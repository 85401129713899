<template>
  <div>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Assign {{ project.name }}
      </v-card-title>

      <v-card-text>
        <v-autocomplete
          prepend-icon="mdi-account-group"
          v-model="chosen_groups"
          :items="groups"
          item-text="name"
          item-value="_id"
          label="Groups"
          color="primary"
          chips
          deletable-chips
          multiple
          small-chips
        ></v-autocomplete>

        <v-autocomplete
          prepend-icon="mdi-account"
          v-model="chosen_users"
          :items="users"
          item-text="fullname"
          item-value="_id"
          label="Users"
          color="secondary"
          chips
          deletable-chips
          multiple
          small-chips
        ></v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancel"> Cancel </v-btn>

        <v-btn color="primary" @click="updateAssignment"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PermissionsForm',

  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      users: [],
      groups: [],
      chosen_groups: [],
      chosen_users: [],
    }
  },
  mounted() {
    this.chosen_groups = this.project.groups
    this.chosen_users = this.project.users
    this.getGroups()
    this.getUsers()
  },
  methods: {
    updateAssignment() {
      this.dialog = false
      let data = {
        _id: this.project._id,
        groups: this.chosen_groups,
        users: this.chosen_users,
      }

      this.$http.post(`${this.$config.api}/projects/set_access`, data).then(
        (res) => {
          console.log(res.data)
          this.$store.dispatch('snack', `Project access updated`)
          this.$emit('update-complete')
        },
        (err) => {
          console.log('Error contacting API')
          console.dir(err)
        }
      )
    },
    getGroups() {
      this.groups = []
      this.$http.get(`${this.$config.api}/groups/all`).then(
        (res) => {
          this.groups = res.data
          console.log(res.data.length + ' groups retrieved from db')
        },
        (err) => {
          console.log('Error contacting API')
          console.dir(err)
        }
      )
    },

    getUsers() {
      this.users = []
      this.$http.get(`${this.$config.api}/users/all`).then(
        (res) => {
          this.users = res.data
          console.log(res.data.length + ' users retrieved from db')
        },
        (err) => {
          console.log('Error contacting API')
          console.dir(err)
        }
      )
    },

    cancel() {
      this.$emit('close-dialog')
    },
  },
}
</script>
