<template>
  <div @click="expanded = !expanded">
    <v-chip small color="primary">
      {{ group.name }}
    </v-chip>
    <span v-if="expanded">
      <v-chip
        small
        color="blue lighten-4"
        v-for="m in group.members"
        :key="m._id"
      >
        {{ m.fullname }}
      </v-chip>
    </span>
  </div>
</template>

<script>
export default {
  name: 'GroupMembers',
  props: {
    group: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      expanded: true,
    }
  },
  methods: {},
  mounted() {},
}
</script>
