<template>
  <span>

    <v-dialog v-model="gbrowser" max-width="1200px">
      <span ref="container">
      <v-card height="90vh">
        <v-card-title class="headline grey lighten-2" primary-title>
          Genome Browser
        </v-card-title>
        <v-card-text>
          <span v-if="showBrowser">
            <iframe :src="gbrowserUrl" height="800px" width="100%"></iframe>
          </span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="gbrowser = false">
            Close
          </v-btn>
        </v-card-actions>

      </v-card>
        </span>

    </v-dialog>

    <v-dialog v-model="session_dialog" max-width="1200px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <v-icon class="mr-2">mdi-chart-timeline</v-icon> Create Genome Browser Session
        </v-card-title>
        <v-card-text v-if="trackList.length">
          <v-stepper v-model="genomeStepper" non-linear>
            <v-stepper-header>
              <v-stepper-step step="1" :editable="hub.length ? false : true">
                Select Tracks
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" :editable="hub.length ? false : true">
                Select Genome
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3" :editable="hub.length ? false : true">
                Submit
              </v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">


                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="trackList"
                    item-key="filename"
                    show-select
                    class="elevation-1 ma-2"
                    dense
                    disable-pagination
                    hide-default-footer
                >
                  <template v-slot:item.title="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.title"
                        @save="save"
                    >
                      <v-hover v-slot="{ hover }">
                        <span :class="hover ? 'text-decoration-underline' : ''">
                          {{ props.item.title }}
                        </span>
                      </v-hover>
                      <template v-slot:input>
                        <v-text-field
                            v-model="props.item.title"
                            label="Edit"
                            single-line
                            counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.size="props">
                    {{props.item.size | prettyBytes }}
                  </template>

                  <template v-slot:item.color="props">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            :color="props.item.color"
                            dark
                            small
                            v-on="on"
                        >
                        </v-btn>
                      </template>
                      <v-color-picker
                          value="#7417BE"
                          v-model="props.item.color"
                          hide-inputs
                          class="mx-auto"
                      ></v-color-picker>
                    </v-menu>
                  </template>
                </v-data-table>

                  <v-row>
                  <v-col class="text-right">
                    <v-btn
                        color="primary"
                        @click="genomeStepper = 2"
                    > Next </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">

                <div class="pa-4">
                  <v-container fluid>
                    <v-row dense>
                      <v-col
                          v-for="g in genomeTypes"
                          :key="g"
                          :cols="4"
                      >
                        <v-card>
                          <v-img
                              :src="$genomes[g].image"
                              class="white--text align-end"
                              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                              height="100px"
                          >
                            <v-card-title v-text="g"></v-card-title>
                          </v-img>

                          <v-card-text>
                              <v-chip
                                  v-for="_g in $genomes[g].genomes"
                                  :key="_g" @click="selectGenome(_g, g)"
                                  :input-value="selectedGenome == _g"
                                  filter
                              >
                                {{ _g }}
                              </v-chip>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">

                <div class="ma-4">
                  <v-text-field
                      v-model="session_title"
                      label="Session Title"
                      :disabled="hub.length ? true : false"
                  ></v-text-field>
                </div>

                <v-btn color="success" @click="makeSession" v-show="!hub.length" :disabled="!session_title.length"> Save Session </v-btn>

                <div v-show="hub.length" class="ma-4">
                  <span ref="container">
                  <v-row>
                      <v-text-field label="genome browser url" v-model="gbrowserUrl" hide-details disabled></v-text-field>
                  </v-row>

                  <v-row class="mt-4">
                    <v-col
                        cols="12"
                        class="py-2"
                    >
                    <v-btn
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="copyBrowser"
                    >
                      Copy Link
                      <v-icon
                          right
                          dark
                      >
                        mdi-clipboard-check
                      </v-icon>
                    </v-btn>
                    <v-btn
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="openBrowser(false)"
                    >
                      Open Here
                      <v-icon
                          right
                          dark
                      >
                        mdi-chart-timeline
                      </v-icon>
                    </v-btn>
                    <v-btn
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="openBrowser(true)"
                    >
                      Open In Tab
                      <v-icon
                          right
                          dark
                      >
                        mdi-open-in-new
                      </v-icon>
                    </v-btn>
                    <v-btn
                        color="blue-grey"
                        class="ma-2 white--text"
                        disabled
                    >
                      Share
                      <v-icon
                          right
                          dark
                      >
                        mdi-share
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                  </span>
                </div>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="session_dialog = false">
            Close
          </v-btn>

        </v-card-actions>

      </v-card>

    </v-dialog>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <div v-on="on" class="d-inline-block">
          <v-btn
              class="my-2"
              @click.stop="reset"
              :disabled="!trackList.length"
          >
          <v-icon color="info"> mdi-chart-timeline </v-icon>
            <span v-show="bigbutton"> Create Session</span>
        </v-btn>
      </div>
      </template>
      <span>
        {{tooltipText}}
      </span>
    </v-tooltip>

  </span>
</template>

<script>
  export default {
    name: 'SessionDialog',
    props: {
      tracks: Array,
      dataproduct: String,
      bigbutton: {type: Boolean, default: false}
    },
    computed: {
      tooltipText() {
        console.log('updating tooltext');
        if(this.trackList.length) {
          return 'Select files to view in genomics browser'
        } else {
          return 'No valid track files selected'
        }
      },
      genomeTypes() {
        return Object.keys(this.$genomes)
      }
    },
    data() {
      return {
        session_dialog: false,
        gbrowser: false,
        showBrowser: false,
        trackList: [],
        selected: [],
        genomeStepper: 1,
        selectedGenome: '',
        selectedGenomeType: '',
        headers: [
          { text: 'Filename', value: 'filename' },
          { text: 'Title', value: 'title' },
          { text: 'Size', value: 'size' },
          { text: 'Color', value: 'color' },
        ],
        track_json: [],
        hub_url: '',
        hub: '',
        gbrowserUrl: '',
        menu: false,
        session_title: ''
      }
    },
    methods: {
      save () {
        this.$store.dispatch('snack', `Updated title`)
      },
      reset () {
        this.genomeStepper = 1;
        this.hub_url = '';
        this.hub = '';
        this.session_title = '';
        this.selectedGenome = '';
        this.selectedGenomeType = '';
        this.session_dialog = true;
      },
      selectGenome (genome, genomeType) {
        this.selectedGenome = genome;
        this.selectedGenomeType = genomeType;
        this.genomeStepper = 3;
      },
      makeTrackList() {
        console.log('TRACKS', this.tracks);
        if(!this.tracks) return [];
        let bamfiles = this.tracks.filter( t => {
          return t.path.endsWith('bam') || t.path.endsWith('bw') || t.path.endsWith('vcf') || t.path.endsWith('bw');
        })
        this.trackList = bamfiles.map( f => {
          let fname = f.path.split('\\').pop().split('/').pop();
          let title = fname.split('.')[0]
          let dp = f.dataproduct !== undefined ? f.dataproduct : this.dataproduct
          return {
            filename: fname,
            size: f.size,
            title: title,
            color: '#2669a3',
            dataproduct: dp
          };
        });

        this.selected = this.trackList;

      },
      makeSession() {

        let data = {
          tracks: this.selected,
          title: this.session_title,
          genome: this.selectedGenome,
          genome_type: this.selectedGenomeType
        }
        //
        let self = this;

        this.$http.post(`${this.$config.api}/sessions/new`, data).then(
          (res) => {
            console.log(res.data)
            self.$store.dispatch('snack', `Track Session Created`)
            self.hub_url = `${this.$config.api}/sessions/datahub/${res.data._id}`;
            self.gbrowserUrl = `https://epigenomegateway.wustl.edu/browser/?genome=${res.data.genome}&hub=${self.hub_url}`;


            self.$http.get(self.hub_url)
            .then(res => {
              console.log(res.data);
              self.hub = res.data;
            })
          },
          (err) => {
            console.log('Error contacting API')
            console.dir(err)
          }
        )
      },
      openBrowser(tab) {
        if(!tab) {
          this.gbrowser = true;
          this.$nextTick(() => { this.showBrowser = true; })
        } else {
          window.open(this.gbrowserUrl, '_blank');
        }
      },

      copyBrowser() {
        let container = this.$refs.container
        this.$copyText(
          this.gbrowserUrl,
          container
        ).then(
          () => {
            this.$store.dispatch(
              'snack',
              `Browser URL copied to clipboard `
            )
          },
          function (e) {
            console.log(e)
          }
        );
      },
    },
    mounted() {
      this.makeTrackList();
    },
    watch: {
      tracks: function(val) {
        console.log(val);
        this.makeTrackList();
      }
    }

  }
</script>
