<template>
  <div>
    <div class="text-center" v-if="loading">
      Checking track files
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>

    <div v-if="!loading && !onDisk">
      <v-container style="max-width: 800px">
        <v-scroll-x-transition>
        <v-container class="text-h5" v-if="!thaw_request">
          <v-icon large color="blue lighten-2">mdi-information</v-icon>
          The following tracks are not currently available. Select the tracks you would like to stage to disk.
        </v-container>
        </v-scroll-x-transition>

        <v-scroll-x-transition>
        <v-container class="text-h5" v-if="thaw_request">
          <v-icon large color="blue lighten-2">mdi-information</v-icon>
          The selected tracks have been submitted for staging.  Staging may take several hours depending on the size of the datasets requested. <span v-if="notifyCheckbox">An email notification will be sent to {{notify}} when the staged data are available.</span>
        </v-container>
        </v-scroll-x-transition>


        <v-slide-x-transition duration="2000">
      <v-card v-if="!loading">
        <v-list v-if="!thaw_request">
          <template v-for="(track, i) in session.tracks">
            <v-divider
                v-if="i !== 0"
                :key="`${i}-divider`"
            ></v-divider>

            <v-list-item :key="`${i}-${track.filename}`">
              <v-list-item-action>
                <v-checkbox
                    v-model="track.selected"
                    :color="track.selected && 'grey' || 'primary'"
                >
                  <template v-slot:label>
                    <div
                        :class="track.selected && 'grey--text' || 'primary--text'"
                        class="ml-4"
                    >{{track.filename}} | {{track.size | prettyBytes }}</div>
                  </template>
                </v-checkbox>
              </v-list-item-action>

              <v-spacer></v-spacer>

              <v-scroll-x-transition>
                <v-icon
                    v-if="track.selected"
                    color="success"
                >
                  mdi-check
                </v-icon>
              </v-scroll-x-transition>
            </v-list-item>
          </template>
        </v-list>
        <v-card-actions>


          <v-col
              v-if="!thaw_request"
              cols="4"
          >
            <v-checkbox
                v-model="notifyCheckbox"
                label="Send notification"
            ></v-checkbox>
            <v-text-field
                v-model="notify"
                label="E-mail"
                :rules="emailRules"
            ></v-text-field>
          </v-col>

          <v-btn
              v-if="!thaw_request"
              color="green lighten-2"
              @click="requestProducts"
              :disabled="!selectedTracks.length"
          >
            Request Tracks
          </v-btn>
          <v-btn
              color="orange lighten-2"
              @click="onDisk = true"
          >
            Continue to genome browser
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-slide-x-transition>
      </v-container>

    </div>
    <div v-if="!loading && onDisk">
      <span v-if="!tab">
          <iframe :src="gbrowserUrl" height="800px" width="100%"></iframe>
      </span>
      <span v-if="tab">
          <iframe :src="gbrowserUrl" frameborder="0" scrolling="yes" seamless="seamless" style="display:block; width:100%; height:100vh;"></iframe>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenomeBrowser',
  props: {
    session: Object,
    tab: Boolean,
  },
  data() {
    return {
      hubUrl: '',
      gbrowserUrl: '',
      onDisk: false,
      loading: true,
      thaw_request: false,
      notify: '',
      notifyCheckbox: false,
      emailRules: [
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  computed: {
    selectedTracks: function() {
      return this.session.tracks.filter( t => { return t.selected })
    }
  },
  methods: {
    makeDatahub() {
      this.hubUrl = `${this.$config.api}/sessions/datahub/${this.session._id}`;
      this.gbrowserUrl = `https://epigenomegateway.wustl.edu/browser/?genome=${this.session.genome}&hub=${this.hubUrl}`;
    },
    checkTracks() {
      let self = this;
      this.loading = true;
      setTimeout(() =>
          self.$http.get(`${this.$config.api}/sessions/checkFiles/${this.session._id}`)
              .then((response) => {
                self.onDisk = response.data.status;
                self.loading = false;
              })
          , 1200);

    },
    requestProducts() {

      let datasets = new Set();
      let requested = {};
      this.session.tracks.forEach(t => {
        if(t.selected) {
          let id = t.dataproduct;
          datasets.add(id);
          requested[id] = false;
        }
      })

      let local_session = JSON.parse(JSON.stringify(this.session));

      local_session['staging'] = {
        requested: requested,
        requestedAt: new Date(),
        completed: false,
        notify: this.notify,
      };

      this.$http.patch(`${this.$config.api}/sessions/${this.session._id}`, local_session).then(
          (res) => {
            console.log(res.data);
          }
      );

      datasets.forEach(d => {
        let data = {
          _id: d,
          notify: false,
        }

        this.$http.post(`${this.$config.api}/dataproducts/request`, data).then(
            (res) => {
              console.log(res.data)
              //this.$store.dispatch('snack', `Data product requested`)
              this.thaw_request = true;
              // this.onDisk = true;
              // signal to parent to poll for updates to data products
              // this.$emit('start-poll')
            },
            (err) => {
              console.log('Error contacting API')
              console.dir(err)
            }
        )

      })

    },
  },
  mounted() {
    this.makeDatahub();
    this.checkTracks();
  },
}
</script>

<style>
.slide-x-transition-enter-active {
  background-color: #b2fab2;
  transition: background-color 2s, all 2s;
}
.slide-x-transition-enter-to {
  background-color: white;
}
.slide-x-transition-leave-active {
  background-color: white;
  transition: background-color 2s, all 2s;
}
.slide-x-transition-leave-to {
  background-color: #fccece;
}
</style>
