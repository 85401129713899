<template>
  <div>
    <div class="d-flex flex-row justify-end">
      <span class="text-h6 mr-2"> {{ countText }}</span>
      <span v-if="visibleSize != '0.00 TB'" class="text-h6 mr-2">
        ({{ visibleSize }})
      </span>
    </div>
  </div>
</template>

<script>
export default {
  // https://github.com/IUSCA/CMG/issues/116
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      //   visibleSize: 0,
    }
  },
  created() {
    // console.log('ItemsCount -> items', this.items)
    console.log('ItemsCount -> total', this.total)
    // console.log('countText', this.countText())
  },
  computed: {
    countText() {
      // if an explicit total is passed in, use that over the items
      if (this.total !== -1) {
        if (this.total === 1) {
          return `${this.total} Item`
        } else {
          return `${this.total} Items`
        }
      } else {
        if (this.items.length === 1) {
          return `1 Item`
        } else {
          return `${this.items.length} Items`
        }
      }
    },
    visibleSize() {
      // console.log('Current items:', items.length, items)
      let size = 0
      for (let item of this.items) {
        if (Object.prototype.hasOwnProperty.call(item, 'size')) {
          size += item.size
        }
      }
      return this.terabytes(size)
    },
  },
  methods: {
    // computed ??
    terabytes(size) {
      if (typeof size !== 'number' || isNaN(size)) {
        throw new TypeError('Expected a number')
      }

      var marker = 1024 // Change to 1000 if required
      var decimal = 2
      // var kiloBytes = marker // One Kilobyte is 1024 bytes
      // var megaBytes = marker * marker // One MB is 1024 KB
      // var gigaBytes = marker * marker * marker // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker // One TB is 1024 GB

      // // return bytes if less than a KB
      // if(bytes < kiloBytes) return bytes + " Bytes";
      // // return KB if less than a MB
      // else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // // return MB if less than a GB
      // else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // // return GB if less than a TB
      return (size / teraBytes).toFixed(decimal) + ' TB'
    },
  },
}
</script>
